import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';
import { API_USER_URL } from '../../connection.data';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public authService: AuthService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log("intercept token");
        if(req.url == `${API_USER_URL}/login`){
            return next.handle(req);
        }else{
            const token = this.authService.getToken();
            // console.log("authservice token: ");console.log(token);
            if(token){
                req = req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${token}`)
                });
            }
            // console.log("new req: ");console.dir(req);
            return next.handle(req);
        }        
    }
}