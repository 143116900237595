/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

// console.log = (...args)=>{}
// console.dir = (...args)=>{}
// console.table = (...args)=>{}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
