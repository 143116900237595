import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_USER_URL } from '../../connection.data';

@Injectable()
export class UserService{
    constructor(private http: HttpClient){
    }

    forgotPassword(user: any):Observable<any>{
        return this.http.post(`${API_USER_URL}/password/forgot`, user);
    }
}