import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './modules/layout/base/base.component';
import { LoginGuard } from './guard/login.guard';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent, 
    children: [
      {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'prefix'                
      },
      {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'pages',
        canActivateChild: [LoginGuard],
        loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
