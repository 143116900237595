import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Cler Ticket';
  data = [
    [10, 20, 30],
    [5, 60, 20],
    [10, 0, 1]
  ];
  legends = ['smart', 'uno a uno', 'duo']
  categories = ['lunes', 'martes', 'miercoles']
  loading = true;
  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    setTimeout(()=>{
      this.loading = false;
    }, 500)
  }
}
