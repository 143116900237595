import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';
import { DatosService } from './core/legacy/datos.service';
import { LayoutModule } from './modules/layout/layout.module';
import { SharedModule } from './modules/shared/shared.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AuthService } from './core/auth/services/auth.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuItems } from './modules/shared/menu-items/menu-items';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { BeneficiaryService } from './core/beneficiary/beneficiary.service';
import { GroupService } from './core/user/services/group.service';
import { VoucherTransactionService } from './core/transactions/voucher-transaction.service';
import { RechargeBatchLoadService } from './core/batch-load/services/recharge-batch-load.service';
import { VoucherService } from './core/voucher/voucher.service';
import { PartnerService } from './core/entity/services/partner.service';
import { EntityTypeService } from './core/entity/services/entity-type.service';
import { SalesBatchLoadService } from './core/transactions/sales-batch-load.service';
import { SmuCampaignService } from './core/smu/services/smu-campaign.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/_base/interceptors/token.interceptor';
import { PermissionManagerService } from './core/_base/permissions/services/permission-manager.service';
import { AdquisitionUnitService } from './core/adquisition-unit/services/adquisition-unit.service';
import { AssociatedService } from './core/associated/services/associated.service';
import { BatchLoadStatusService } from './core/batch-load/services/batch-load-status.service';
import { VoucherBatchLoadService } from './core/batch-load/services/voucher-batch-load.service';
import { ContractStatusService } from './core/contracts/services/contract-status.service';
import { ContractTemplateService } from './core/contracts/services/contract-template.service';
import { ContractService } from './core/contracts/services/contract.service';
import { EntityService } from './core/entity/services/entity.service';
import { CityService } from './core/location/services/city.service';
import { CommuneService } from './core/location/services/commune.service';
import { RegionService } from './core/location/services/region.service';
import { PaymentService } from './core/payment/services/payment.service';
import { SaleOrderService } from './core/sales/services/sale-order.service';
import { SmartBatchLoadService } from './core/smart-voucher/services/smart-batch-load.service';
import { SmartVoucherAssignationService } from './core/smart-voucher/services/smart-voucher-assignation.service';
import { SmartVoucherTypeService } from './core/smart-voucher/services/smart-voucher-type.service';
import { SmartVoucherService } from './core/smart-voucher/services/smart-voucher.service';
import { SmuMonederoService } from './core/smu/services/smu-monedero.service';
import { UserBatchLoadService } from './core/user/services/user-batch-load.service';
import { UserBeneficiaryBatchLoadService } from './core/user/services/user-beneficiary-batch-load.service';
import { UserService } from './core/user/services/user.service';
import { BarcodeService } from './core/voucher/barcode.service';
import { VoucherProviderService } from './core/voucher/voucher-provider.service';
import { VoucherStateService } from './core/voucher/voucher-state.service';
import { LoginGuard } from './guard/login.guard';
import localeEs from '@angular/common/locales/es-CL';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { UsersV2Service } from './core/user/services/userv2.service';
import {BatchLoadReportDetailService} from "./core/batch-load/services/batch-load-report-detail.service";

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    LayoutModule,
    SharedModule,
    SweetAlert2Module.forRoot(),
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass :'toast-top-right'
    }),
    BlockUIModule.forRoot()
  ],
  providers: [
    MenuItems,
    DatosService,
    LoginGuard,
    ContractService,
    ContractTemplateService,
    SmartVoucherAssignationService,
    ContractStatusService,
    SmartVoucherService,
    AuthService,
    AssociatedService,
    PaymentService,
    VoucherProviderService,
    VoucherStateService,
    SmartBatchLoadService,
    BatchLoadStatusService,
    VoucherBatchLoadService,
    SaleOrderService,
    AdquisitionUnitService,
    EntityService,
    UserBatchLoadService,
    UserBeneficiaryBatchLoadService,
    BarcodeService,
    SmartVoucherTypeService,
    CommuneService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    CityService,
    RegionService,
    PermissionManagerService,
    UserService,
    SmuMonederoService,
    SmuCampaignService,
    SalesBatchLoadService,
    EntityTypeService,
    PartnerService,
    VoucherService,
    RechargeBatchLoadService,
    VoucherTransactionService,
    GroupService,
    BeneficiaryService,
    CurrencyPipe,
    ToastrService,
    UsersV2Service,
    BatchLoadReportDetailService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
