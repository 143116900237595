import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class LoginGuard  {
  
  constructor(private route: Router){}

  public sessionId!: string|number|null;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      //console.log('no');
      return true;
  }

  canActivateChild(
    
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{
    let url: string = state.url;
    this.sessionId = localStorage.getItem('userId');
    //console.log('Entering canActivateChild', this.sessionId, url);
    if (url == '/auth/login' || url.startsWith('/pages/late-assignation'))
      return true;
    else if(this.sessionId){
      //console.log('esta logeado');      
      return true;
    }else{
      //console.log('no esta logeado');
      localStorage.clear();      
      this.route.navigate(['/']);            
      return false;
    }    
  }

}

